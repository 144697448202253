@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap')

body
  font-family: 'Montserrat', sans-serif
  background-color: #f4f4f4
  margin: 0
  padding: 0

header, footer
  background-color: #007bff
  color: white
  padding: 10px 0
  text-align: center

.navbar
  display: flex
  justify-content: space-between
  align-items: center
  background-color: #007bff
  padding: 15px 30px
  color: white
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1)

.nav-header
  font-size: 1.5em
  font-weight: 600

.button
  border: none
  padding: 10px 20px
  cursor: pointer
  color: white
  border-radius: 5px
  font-weight: 600
  transition: background-color 0.3s ease, transform 0.3s ease

.save-button
  background-color: #28a745
  &:hover
    background-color: #218838
    transform: scale(1.05)

.button-danger
  background-color: #dc3545
  &:hover
    background-color: #c82333
    transform: scale(1.05)

.banned
  opacity: 0.5
  cursor: not-allowed

.page-body
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  gap: 20px
  padding: 20px
  max-width: 1200px
  margin: 20px auto
  background-color: #fff
  border-radius: 10px
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1)

.page-body h3
  width: 100%
  margin-bottom: 20px
  font-weight: 600

.form-group
  margin-bottom: 15px
  width: 100%

.form-label
  display: block
  margin-bottom: 5px
  font-weight: 600

.form-input, .form-select
  width: 100%
  padding: 12px
  border: 1px solid #ccc
  border-radius: 4px
  transition: border-color 0.3s ease, box-shadow 0.3s ease

.form-input:focus, .form-select:focus
  border-color: #80bdff
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.25)

.card
  flex: 1 1 calc(25% - 20px)
  border: 1px solid #ccc
  border-radius: 10px
  padding: 20px
  transition: box-shadow 0.3s ease
  background-color: #fff

.card:hover
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1)

.card h4
  margin-top: 0

footer
  padding: 10px
  background-color: #007bff
  color: white
  text-align: center
